import React, {lazy, Suspense} from 'react';
import './Textual.css'
import {useTranslation} from "react-i18next";

const Privacy: React.FC = () => {
    const {i18n} = useTranslation();

    const PrivacyComponent = lazy(() =>
        import(`../i18n/pages/privacy/${i18n.language.split("_")[0]}`).catch(() =>
            import('../i18n/pages/privacy/en')
        )
    );

    return (
        <>
            <div className="textual-page">
                <Suspense fallback={<div>Loading...</div>}>
                    <PrivacyComponent/>
                </Suspense>


            </div>
        </>
    );
}

export default Privacy;
