import React from 'react';
import SearchComponent from './SearchComponent';
import {ArticleAnalysisRequest} from '../../../vibelens/VibeLensServer';
import {useTranslation} from "react-i18next";

interface AnalysisSearchProps {
    onSearch: (data: ArticleAnalysisRequest) => void;
}

const AnalysisSearch: React.FC<AnalysisSearchProps> = ({onSearch}) => {
    const {t} = useTranslation()

    return (
        <SearchComponent
            onSearch={onSearch}
            placeholders={[t('articles.search.placeholder')]}
            buttonText={t('articles.search.button')}
        />
    );
};

export default AnalysisSearch;
