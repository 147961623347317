import React from 'react';
import {getEmojiForVibe, getStrongestVibeExcludeNeutral} from "../../../utils/vibes";
import "./ChartTitle.css"
import {ArticleClassificationResponse} from "../../../vibelens/VibeLensServer";
import {getSite} from "../../../utils/Text";

interface TitleAnalysisProps {
    title: string
    url: string;
    titleAnalysis: ArticleClassificationResponse;
    color: string
}

const ChartTitle: React.FC<TitleAnalysisProps> = ({title, url, titleAnalysis, color}) => {
    const strongestVibe = getStrongestVibeExcludeNeutral(titleAnalysis.results);
    const strongestVibeEmoji = getEmojiForVibe(strongestVibe);

    return (

        <div className="title-analysis-container">
            <div className="title-emoji emoji" title={strongestVibe}>{strongestVibeEmoji}</div>
            <h2 className="title-text">
                <a className="title-url title-text" target="_blank" href={url} title={url}
                   rel="noopener noreferrer">
                    {title}
                </a>
                <text className="title-text"> | </text>
                <text className="title-text" style={{color: color}}>{getSite(url)}</text>
            </h2>
        </div>

    );
};

export default ChartTitle;
