import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';
import './App.css';
import AnalysisSearch from './components/articles/search/AnalysisSearch';
import ArticleAnalysisResult from './components/articles/result/AnalysisResult';
import AnalysisResult from './components/articles/result/AnalysisResult';
import Header from "./components/Header";
import ComparisonSearch from "./components/articles/search/ComparisonSearch";
import ComparisonResult from "./components/articles/result/ComparisonResult";
import {ArticleAnalysisRequest, ArticleComparisonRequest} from "./vibelens/VibeLensServer";
import Footer from "./components/Footer";
import About from "./components/About";
import Privacy from "./components/Privacy";
import ErrorComponent from "./components/error/ErrorComponent";
import {useTheme} from "./theme/ThemeContext";

const App: React.FC = () => {

    const themeContext = useTheme()

    return (<>
            <Router>

                <div className={`App ${themeContext.theme.name}-theme`}>
                    <Header/>
                    <div className="app-background-base"></div>
                    <div className="app-background-gradient"
                         style={{backgroundImage: themeContext.background}}></div>
                    <div className="app-background-blur" style={{background: themeContext.blurColor}}></div>
                    <div className="app-content">
                        <Routes>
                            <Route path="/"
                                   element={<AnalysisSearchPage/>}/>

                            <Route path="/articles/analysis"
                                   element={<AnalysisSearchOrQueryPage/>}/>

                            <Route path="/articles/analysis/:id"
                                   element={<AnalysisResultByIdPage/>}/>

                            <Route path="/articles/comparisons"
                                   element={<ComparisonSearchOrQueryPage/>}/>

                            <Route path="/articles/comparisons/:id"
                                   element={<ComparisonResultByIdPage/>}/>

                            <Route path="/about"
                                   element={<About/>}/>

                            <Route path="/privacy-policy"
                                   element={<Privacy/>}/>

                            <Route path="/error"
                                   element={<ErrorPage/>}/>

                            <Route path="*"
                                   element={<Navigate to="/"/>}/> {}
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </Router>
        </>
    );
}

const AnalysisSearchPage: React.FC = () => {
    const navigate = useNavigate();

    const handleSearch = (data: ArticleAnalysisRequest) => {
        navigate(`/articles/analysis?url=${encodeURIComponent(data.url)}`);
    };

    return <AnalysisSearch onSearch={handleSearch}/>;
};

const AnalysisResultByIdPage: React.FC = () => {
    let {id} = useParams();

    return <AnalysisResult analysisId={id} url={null}/>;
};

const AnalysisSearchOrQueryPage: React.FC = () => {
        const location = useLocation();
        const query = new URLSearchParams(location.search);
        const url = query.get('url');

        if (url === null) {
            return <AnalysisSearchPage/>
        } else {
            return <ArticleAnalysisResult url={url} analysisId={undefined}/>;
        }
    }
;

const ComparisonSearchPage: React.FC = () => {
    const navigate = useNavigate();

    const handleSearch = (data: ArticleComparisonRequest) => {
        navigate(`/articles/comparisons?url1=${encodeURIComponent(data.url_1)}&url2=${encodeURIComponent(data.url_2)}`);
    };

    return <ComparisonSearch onSearch={handleSearch}/>;
};

const ComparisonSearchOrQueryPage: React.FC = () => {
        const location = useLocation();
        const query = new URLSearchParams(location.search);

        const url1 = query.get('url1');
        const url2 = query.get('url2');

        if (url1 === null || url2 === null) {
            return <ComparisonSearchPage/>
        } else {
            return <ComparisonResult url1={url1} url2={url2} comparisonId={undefined}/>;
        }
    }
;

const ComparisonResultByIdPage: React.FC = () => {
    let {id} = useParams();

    return <ComparisonResult comparisonId={id} url1={null} url2={null}/>;
};

const ErrorPage: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const statusCode = query.get('statusCode');

    return <ErrorComponent statusCode={statusCode}/>;
};

export default App;
