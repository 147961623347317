import React from 'react';
import './Footer.css';
import {useTranslation} from "react-i18next";

const Footer: React.FC = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="footer-container">
                <a className="footer-item" href="/about">{t('footer.about')}</a>
                <a className="footer-item" href="/privacy-policy">{t('footer.privacy-policy')}</a>
            </div>
        </>
    );
}

export default Footer;
