import {ArticleAnalysisResponse} from "../../../vibelens/VibeLensServer";
import React, {useEffect, useState} from "react";
import {Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {percentageFormatterNoFractions, toChartItem} from "./ChartFunctions";
import {getChartFontColor} from "../../../utils/ChartFormat";
import {EmojiVibeTick} from "./EmojiVibeTick";
import ThemedTooltip from "./EmojiVibeTooltip";
import {useTheme} from "../../../theme/ThemeContext";


interface AnalysisChartProps {
    result: ArticleAnalysisResponse
}


const VibeAnalysisChart: React.FC<AnalysisChartProps> = ({result}) => {

    const themeContext = useTheme()
    const [stroke, setStroke] = useState(getChartFontColor(themeContext.theme));

    useEffect(() => {
        setStroke(getChartFontColor(themeContext.theme))
    }, [themeContext.theme]);

    const dataForBarChart = Object.entries(result.content_analysis.results)
        .filter(([vibe, value]) => vibe !== 'neutral')
        .map(toChartItem)
        .sort((a, b) => b.value - a.value)
        .slice(0, 6);

    return (
        <ResponsiveContainer height={250} minWidth={200}>
            <BarChart data={dataForBarChart}
                      margin={{top: 15, right: 0, left: 0, bottom: 30}}>
                <XAxis
                    dataKey="vibe"
                    stroke={stroke}
                    tick={(props) => <EmojiVibeTick {...props} themeContext={themeContext}/>}
                    tickLine={true}
                    interval={0}
                />
                <YAxis stroke={stroke} tickFormatter={percentageFormatterNoFractions}/>
                <Tooltip content={<ThemedTooltip/>} cursor={{fill: 'none'}}/>
                <Bar dataKey="value" name={result.title.substring(0, 26).trim() + "..."}
                     barSize={50}>
                    {
                        dataForBarChart.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={(entry.color)}/>
                        ))
                    }
                    <LabelList
                        dataKey="value"
                        formatter={percentageFormatterNoFractions}
                        position="top"/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}


export default VibeAnalysisChart