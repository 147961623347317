import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import Theme from './Theme';
import {Gradients} from './Gradients';

interface ThemeContext {
    themeName: string;
    theme: Theme;
    changeTheme: (newThemeName: string) => void;
    background: string;
    setBackground: (newBackgroundGradient: string) => void;
    blurColor: string;
}

const Context = createContext<ThemeContext | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [themeName, setThemeName] = useState(getCurrentThemeName());
    const [theme, setTheme] = useState(getThemeFromName(themeName));
    const [background, setBackground] = useState(Gradients.getRandomGradient(theme));
    const [blurColor, setBlurColor] = useState(themeToBlurColor);


    useEffect(() => {
        setThemeName(themeName);
        setTheme(theme);
        setBackground(background);
    }, [themeName, theme, background]);

    useEffect(() => setBlurColor(themeToBlurColor()), [theme])

    initSystemThemeListener();


    function themeToBlurColor() {
        if (theme.isLight()) {
            return Gradients.hexToRgba("#ffffff", 0.3)
        } else {
            return Gradients.hexToRgba("#000000", 0.5)
        }
    }

    function getThemeFromName(name: string): Theme {
        if (name === 'device') {
            return Theme.getByName(getDeviceTheme());
        } else {
            return Theme.getByName(name);
        }
    }

    function getCurrentThemeName(): string {
        let storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            return storedTheme;
        } else {
            return getDeviceTheme();
        }
    }

    function getDeviceTheme(): string {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    function changeTheme(newThemeName: string): void {
        const newTheme = getThemeFromName(newThemeName);
        setThemeName(newThemeName);
        setTheme(newTheme);
        setBackground(Gradients.getRandomGradient(newTheme));

        localStorage.setItem('theme', newThemeName);
    }

    function initSystemThemeListener(): void {
        if (window.matchMedia) {
            const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
            if (mediaQueryList.addEventListener) {
                mediaQueryList.addEventListener('change', handleSystemThemeChange);
            } else if (mediaQueryList.addListener) {
                mediaQueryList.addListener(handleSystemThemeChange); // For older browsers and JSDOM
            }
        }
    }

    function handleSystemThemeChange(e: MediaQueryListEvent): void {
        if (themeName === 'device') {
            const newThemeName = e.matches ? 'dark' : 'light';
            changeTheme(newThemeName);
        }
    }

    return (
        <Context.Provider value={{
            themeName,
            theme,
            changeTheme,
            background,
            setBackground,
            blurColor
        }}>
            {children}
        </Context.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
