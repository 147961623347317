import React, {useEffect, useState} from 'react';
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {ArticleComparisonResponse} from "../../../vibelens/VibeLensServer";
import {
    chartDataColor1,
    chartDataColor2,
    percentageFormatterNoFractions,
    toComparableChartItem
} from "./ChartFunctions";
import {EmojiVibeTick} from "./EmojiVibeTick";
import {getChartFontColor} from "../../../utils/ChartFormat";
import ThemedTooltip from "./EmojiVibeTooltip";
import {useTheme} from "../../../theme/ThemeContext";


interface ComparisonChartProps {
    result: ArticleComparisonResponse
}

const VibeComparisonChart: React.FC<ComparisonChartProps> = ({result}) => {

    const themeContext = useTheme()
    const [stroke, setStroke] = useState(getChartFontColor(themeContext.theme));

    useEffect(() => {
        setStroke(getChartFontColor(themeContext.theme))
    }, [themeContext.theme]);

    const dataForBarChart = Object.entries(result.article_1.content_analysis.results)
        .filter(([vibe, value]) => vibe !== 'neutral')
        .map(toComparableChartItem(result)).sort((a, b) => (b.value1 + b.value2) - (a.value1 + a.value2))
        .slice(0, 6);

    return (
        <ResponsiveContainer height={250} minWidth={200}>
            <BarChart
                data={dataForBarChart}
                margin={{top: 10, right: 0, left: 0, bottom: 30}}>
                <XAxis
                    dataKey="vibe"
                    stroke={stroke}
                    tick={(props) => <EmojiVibeTick {...props} themeContext={themeContext}/>}
                    tickLine={true}
                    interval={0}
                />
                <YAxis
                    stroke={stroke} tickFormatter={percentageFormatterNoFractions}/>
                <Tooltip content={<ThemedTooltip/>} cursor={{fill: 'none'}}/>

                <Bar
                    dataKey="value1"
                    fill={chartDataColor1}
                    name={`${result.article_1.title.substring(0, 26).trim()}...`}>
                    <LabelList
                        dataKey="value1"
                        formatter={percentageFormatterNoFractions}
                        position="top"
                    />
                </Bar>
                <Bar
                    dataKey="value2"
                    fill={chartDataColor2}
                    name={`${result.article_2.title.substring(0, 26).trim()}...`}
                >
                    <LabelList
                        dataKey="value2"
                        formatter={percentageFormatterNoFractions}
                        position="top"
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default VibeComparisonChart;
