import React, {useEffect, useRef, useState} from 'react';
import VibeLensServer, {ArticleAnalysisResponse, HttpStatusError} from "../../../vibelens/VibeLensServer";
import './ArticleResult.css';
import {getStrongestVibeExcludeNeutral} from "../../../utils/vibes";
import {Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Gradients} from "../../../theme/Gradients";
import {Spinner} from "react-bootstrap";
import {getChartAxisColor, getChartFontColor} from "../../../utils/ChartFormat";
import ChartTitle from "./ChartTitle";
import {
    chartDataColor1,
    percentageFormatter,
    percentageFormatterNoFractions,
    toChartItem
} from "../charts/ChartFunctions";
import {useNavigate} from "react-router-dom";
import VibeAnalysisChart from "../charts/VibeAnalysisChart";
import ThemedTooltip from "../charts/EmojiVibeTooltip";
import "./AnalysisResult.css";
import ShareButtons from "../../ShareButtons";
import {getSite} from "../../../utils/Text";
import {EmojiVibeTick} from "../charts/EmojiVibeTick";
import {useTheme} from "../../../theme/ThemeContext";


interface ArticleAnalysisSearchResultProps {
    url: string | null;
    analysisId: string | undefined;
}


const ArticleAnalysisResult: React.FC<ArticleAnalysisSearchResultProps> = ({
                                                                               url,
                                                                               analysisId,
                                                                           }) => {

    const themeContext = useTheme()

    const navigate = useNavigate();
    const [result, setResult] = useState<ArticleAnalysisResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const isFirstRender = useRef(true);
    const [axisColor, setAxisColor] = useState(getChartAxisColor(themeContext.theme));

    const [fontColor, setFontColor] = useState(getChartFontColor(themeContext.theme));


    useEffect(() => {
        if (result) {
            const highest = getStrongestVibeExcludeNeutral(result.title_analysis.results);
            themeContext.setBackground(Gradients.getGradientForVibe(highest, themeContext.theme));
            setAxisColor(getChartAxisColor(themeContext.theme))
            setFontColor(getChartFontColor(themeContext.theme))
        }
    }, [result, themeContext.theme]);

    function getAnalysis() {
        if (analysisId !== undefined) {
            return VibeLensServer.getAnalysis(analysisId)
        } else if (url !== null) {
            return VibeLensServer.analyze(url);
        }
        throw new Error("Either URLs or ID must be provided")
    }

    useEffect(() => {
        if (result === null) {
            setLoading(true);
            getAnalysis()
                .then(response => {
                    setResult(response)
                    if (analysisId === undefined) {
                        navigate("/articles/analysis/" + response.id)
                    }
                })
                .catch(error => {
                    if (error instanceof HttpStatusError) {
                        navigate("/error?statusCode=" + error.status)
                    } else {
                        navigate("/error")
                    }
                })
                .finally(() => setLoading(false));
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
    }, [result, isFirstRender]);


    if (!result) {
        return loadingScreen()
    }


    const neutralityChart = Object.entries(result.content_analysis.results)
        .filter(([vibe, value]) => vibe === 'neutral')
        .map(toChartItem)
        .sort((a, b) => b.value - a.value)
        .slice(0, 1);

    if (!url && !analysisId) {
        return <div className="results-container">No URL or ID provided</div>;
    }

    function loadingScreen() {
        return <Spinner animation="border"/>;
    }

    let sharedTitle = getSite(result.url) + ": " + result.title;
    let sharedDescription = "Analysis: " + result.title;
    let sharedUrl = window.location.href;

    return (
        <div className="results-container">
            {loading ? loadingScreen() :
                (
                    result && (<>
                            <div className="analysis-component">
                                <div className="single-title-container">
                                    <ChartTitle title={result.title}
                                                color="var(--text-primary-color)"
                                                titleAnalysis={result.title_analysis}
                                                url={result.url}/>
                                </div>
                                <div className="content-analysis-container" title="Content analysis">
                                    <div className="content-analysis-chart">
                                        <VibeAnalysisChart result={result}/>
                                        <ResponsiveContainer className={'neutrality'} height={110} minWidth={200}>
                                            <BarChart title='Neutrality' layout='vertical' data={neutralityChart}>
                                                <YAxis
                                                    type="category"
                                                    dataKey="vibe"
                                                    tick={(props) => <EmojiVibeTick {...props}
                                                                                    xOffset={-25}
                                                                                    yOffset={-22}
                                                                                    themeContext={themeContext}/>}
                                                    stroke={fontColor}/>
                                                <XAxis type="number" stroke={fontColor}
                                                       tickFormatter={percentageFormatterNoFractions}
                                                       domain={[0, 1]}/>
                                                <Tooltip content={<ThemedTooltip/>}
                                                         cursor={{fill: 'none'}}/>
                                                <Legend layout="horizontal" align="center" verticalAlign="bottom"
                                                />
                                                <Bar dataKey="value" fill={chartDataColor1} barSize={30}
                                                     name={result.title.substring(0, 60).trim() + "..."}>
                                                    <LabelList dataKey="value1" formatter={percentageFormatter}
                                                               position="right"/>
                                                </Bar>

                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <ShareButtons
                                    url={sharedUrl}
                                    title={sharedTitle}
                                    description={sharedDescription}/>
                            </div>
                        </>
                    )
                )}
            {error ? (
                <div>{error}</div>) : (<div></div>)
            }
        </div>
    );
}

export default ArticleAnalysisResult;
