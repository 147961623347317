import axios, {AxiosRequestConfig, AxiosResponse} from "axios";


export default class VibeLensServer {

    private static client = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL || `${window.location.protocol}//${window.location.host}/api`,
        timeout: process.env.REACT_APP_API_TIMEOUT === undefined ? 60000 : parseInt(process.env.REACT_APP_API_TIMEOUT),
    });


    public static async analyze(url: string): Promise<ArticleAnalysisResponse> {
        return VibeLensServer
            .request<ArticleAnalysisResponse>('POST', '/v1/articles/analysis', {url})
    }

    static getAnalysis(analysisId: string): Promise<ArticleAnalysisResponse> {
        return VibeLensServer.request('GET', '/v1/articles/analysis/' + analysisId)
    }

    public static async compare(url1: string, url2: string): Promise<ArticleComparisonResponse> {
        const request: ArticleComparisonRequest = {
            url_1: url1,
            url_2: url2
        };
        return VibeLensServer
            .request<ArticleComparisonResponse>('POST', '/v1/articles/comparisons', request);
    }

    public static async getComparison(id: string): Promise<ArticleComparisonResponse> {
        return VibeLensServer
            .request<ArticleComparisonResponse>('GET', `/v1/articles/comparisons/${id}`);
    }


    private static async request<T>(method: string, url: string, data?: any, params?: any): Promise<T> {
        try {
            const config: AxiosRequestConfig = {
                method,
                url,
                data,
                params,
            };
            const response: AxiosResponse<T> = await VibeLensServer.client.request<T>(config);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    throw new HttpStatusError(error.response.status, "Error from server")
                } else if (error.request) {
                    throw new ApiError("No response received from the server.");
                } else {
                    throw new ApiError(error.message);
                }
            } else {
                throw new Error("An unexpected error occurred.");
            }
        }
    }

}

export class ApiError extends Error {

    constructor(message: string) {
        super(message);
        Error.captureStackTrace(this, this.constructor);
    }
}

export class HttpStatusError extends ApiError {

    status: number;

    constructor(status: number, message: string) {
        super(message);
        this.status = status
        Error.captureStackTrace(this, this.constructor);
    }
}


export interface ArticleAnalysisResponse {
    title: string;
    url: string;
    title_analysis: ArticleClassificationResponse;
    content_analysis: ArticleClassificationResponse;
    id: string;
}

export interface ArticleClassificationResponse {
    language: string;
    analyzed_language: string;
    vibe_type: string;
    results: {
        [key: string]: number;
    };
}

export interface ArticleAnalysisRequest {
    url: string
}

export interface ArticleComparisonRequest {
    url_1: string
    url_2: string
}

export interface ArticleComparisonResponse {
    article_1: ArticleAnalysisResponse
    article_2: ArticleAnalysisResponse
    similarity: number
    id: string
}
