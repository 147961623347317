import React from 'react';
import './ErrorComponent.css'
import {useTranslation} from "react-i18next";

interface ErrorProps {
    statusCode: string | null;
}


const ErrorComponent: React.FC<ErrorProps> = ({statusCode}) => {
    const {t} = useTranslation()

    let message
    if (statusCode) {
        const messages: { [key: string]: string } = {
            "404": "errors.404",
            "415": "errors.415",
            "424": "errors.424",
            "500": "errors.500",
            "502": "errors.502",
            "503": "errors.503"
        };

        message = messages[statusCode] || messages["500"];
    } else {
        message = "errors.try-again"
    }


    return (
        <>
            <div className="error-page">
                <div>
                    <h1>{statusCode || t("error.unexpected")}</h1>
                    <h4>{t(message)}</h4>
                </div>
            </div>

        </>
    );
}

export default ErrorComponent;
