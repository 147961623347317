import React, {lazy, Suspense} from 'react';
import './Textual.css'
import {useTranslation} from "react-i18next";

const About: React.FC = () => {


    const {i18n} = useTranslation();

    const AboutComponent = lazy(() =>
        import(`../i18n/pages/about/${i18n.language}`).catch(() =>
            import('../i18n/pages/about/en')
        )
    );

    return (
        <>
            <div className="textual-page">
                <Suspense fallback={<div>Loading...</div>}>
                    <AboutComponent/>
                </Suspense>
            </div>

        </>
    );
}

export default About;
