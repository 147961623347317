export default class Theme {

    public static readonly DARK: Theme = new Theme('dark', true)
    public static readonly LIGHT: Theme = new Theme('light', false)

    private static readonly allThemes: Theme[] = [Theme.DARK, Theme.LIGHT]
    readonly name: string;
    readonly isDarkBase: boolean;

    constructor(name: string, isDarkBase: boolean) {
        this.name = name;
        this.isDarkBase = isDarkBase;
    }

    public static getByName(name: string): Theme {
        return Theme.allThemes.find(theme => theme.name === name) || Theme.LIGHT;
    }

    isDark(): boolean {
        return this.isDarkBase;
    }


    isLight(): boolean {
        return !this.isDarkBase;
    }
}
