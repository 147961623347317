import React, {useEffect, useRef, useState} from 'react';
import VibeLensServer, {ArticleComparisonResponse, HttpStatusError} from "../../../vibelens/VibeLensServer";
import './ArticleResult.css';
import {getStrongestVibeExcludeNeutral} from "../../../utils/vibes";
import {Gradients} from "../../../theme/Gradients";
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import ChartTitle from "./ChartTitle";
import {Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import ProgressBarChart from "../charts/ProgressBarChart";
import {
    chartDataColor1,
    chartDataColor2,
    percentageFormatterNoFractions,
    toComparableChartItem
} from "../charts/ChartFunctions";
import VibeComparisonChart from "../charts/VibeComparisonChart";
import {getChartFontColor} from "../../../utils/ChartFormat";
import ThemedTooltip from "../charts/EmojiVibeTooltip";
import ShareButtons from "../../ShareButtons";
import {getSite} from "../../../utils/Text";
import {EmojiVibeTick} from "../charts/EmojiVibeTick";
import {useTheme} from "../../../theme/ThemeContext";
import {useTranslation} from "react-i18next";

interface ArticleComparisonResultProps {
    url1: string | null;
    url2: string | null;
    comparisonId: string | undefined;
}


const ArticleComparisonResult: React.FC<ArticleComparisonResultProps> = ({
                                                                             url1,
                                                                             url2,
                                                                             comparisonId,
                                                                         }) => {

    const themeContext = useTheme()

    const navigate = useNavigate();
    const [result, setResult] = useState<ArticleComparisonResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const isFirstRender = useRef(true);
    const [stroke, setStroke] = useState(getChartFontColor(themeContext.theme));

    const {t} = useTranslation()

    useEffect(() => {
        if (result) {
            const highest = getStrongestVibeExcludeNeutral(result.article_1.title_analysis.results);
            themeContext.setBackground(Gradients.getGradientForVibe(highest, themeContext.theme));
        }
    }, [result, themeContext.theme]);

    function getComparison() {
        if (comparisonId !== undefined) {
            return VibeLensServer.getComparison(comparisonId);
        } else if (url1 !== null && url2 !== null) {
            return VibeLensServer.compare(url1, url2)
        }
        throw new Error("Either URLs or ID must be provided")

    }

    useEffect(() => {
        if (result === null) {
            setLoading(true);
            getComparison()
                .then(res => {
                    setResult(res)
                    if (comparisonId === undefined) {
                        navigate("/articles/comparisons/" + res.id)
                    }
                })
                .catch(error => {
                    if (error instanceof HttpStatusError) {
                        navigate("/error?statusCode=" + error.status)
                    } else {
                        navigate("/error")
                    }
                })
                .finally(() => setLoading(false));
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
    }, [result, isFirstRender]);

    if (!result) {
        return loadingScreen();

    }

    const neutralityChart = Object.entries(result.article_1.content_analysis.results)
        .filter(([vibe, value]) => vibe === 'neutral')
        .map(toComparableChartItem(result)).sort((a, b) => (b.value1 + b.value2) - (a.value1 + a.value2))
        .slice(0, 1);
    if ((!url1 || !url2) && !comparisonId) {
        return <div className="results-container">No URLs or ID provided</div>;

    }

    function loadingScreen() {
        return <Spinner animation="border"/>;
    }

    let sharedTitle = getSite(result.article_1.url) + " vs " + getSite(result.article_2.url);
    let sharedDescription = 'Comparing: ' + result.article_1.title + ' vs ' + result.article_2.title;
    let sharedUrl = window.location.href;

    return (
        <div className="results-container">
            {loading ? loadingScreen() :
                (
                    result && (
                        <>
                            <div className="analysis-component">
                                <div className="multi-title-container">
                                    <ChartTitle title={result.article_1.title}
                                                color="#8884d8"
                                                titleAnalysis={result.article_1.title_analysis}
                                                url={result.article_1.url}/>
                                    <ChartTitle title={result.article_2.title}
                                                color="#82ca9d"
                                                titleAnalysis={result.article_2.title_analysis}
                                                url={result.article_2.url}/>
                                </div>
                                <div className="content-analysis-container" title="Content analysis">
                                    <div className="content-analysis-chart">
                                        <VibeComparisonChart result={result}/>
                                        <ResponsiveContainer className={'neutrality'} height={130} minWidth={200}>
                                            <BarChart title='Neutrality' layout='vertical' data={neutralityChart}>
                                                <YAxis type="category"
                                                       tick={(props) => <EmojiVibeTick {...props}
                                                                                       xOffset={-25}
                                                                                       yOffset={-22}
                                                                                       themeContext={themeContext}/>}
                                                       dataKey="vibe"
                                                       stroke={stroke}/>
                                                <XAxis type="number" stroke={stroke}
                                                       tickFormatter={percentageFormatterNoFractions}
                                                       domain={[0, 1]}/>
                                                <Tooltip content={<ThemedTooltip/>}
                                                         cursor={{fill: 'none'}}/>
                                                <Legend layout="horizontal" align="center" verticalAlign="bottom"
                                                />
                                                <Bar dataKey="value1" fill={chartDataColor1}
                                                     name={result.article_1.title.substring(0, 30).trim() + "..."}>
                                                    <LabelList dataKey="value1"
                                                               formatter={percentageFormatterNoFractions}
                                                               position="right"/>
                                                </Bar>
                                                <Bar dataKey="value2" fill={chartDataColor2}
                                                     name={result.article_2.title.substring(0, 30).trim() + "..."}>
                                                    <LabelList dataKey="value2"
                                                               formatter={percentageFormatterNoFractions}
                                                               position="right"/>
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>

                                </div>

                                <div className="progress-chart-container">
                                    <ProgressBarChart
                                        percentage={Math.floor(result.similarity * 100)}
                                        label={`${t("comparisons.result.similarity.label")} ${Math.floor(result.similarity * 100)}%`}
                                        tooltip={`${t("comparisons.result.similarity.tooltip")} ${Math.floor(result.similarity * 100)}%`}
                                    />
                                </div>
                                <ShareButtons
                                    url={sharedUrl}
                                    title={sharedTitle}
                                    description={sharedDescription}/>
                            </div>
                        </>
                    )
                )}
        </div>
    );
}

export default ArticleComparisonResult;
