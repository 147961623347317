import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "./theme/ThemeContext";
import * as Sentry from "@sentry/react";
import './i18n';


Sentry.init({
    dsn: "https://f7d3aaad98732a6e32f1d3ca4586c943@o4507719586807808.ingest.de.sentry.io/4507720241184848",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: ["localhost", "vibelens.ai", /^https:\/\/vibelens\.ai\/api/],

    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log result (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
