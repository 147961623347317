import React, {useEffect, useState} from "react";
import {getEmojiForVibe} from "../../../utils/vibes";
import {getChartFontColor} from "../../../utils/ChartFormat";
import {useTheme} from "../../../theme/ThemeContext";
import {useTranslation} from "react-i18next";

interface CustomTickProps {
    x: number;
    y: number;
    payload: {
        value: string;
    };
    xOffset?: number
    yOffset?: number

}

export const EmojiVibeTick: React.FC<CustomTickProps> = ({x, y, payload, xOffset, yOffset}) => {


    const themeContext = useTheme()
    const [stroke, setStroke] = useState(getChartFontColor(themeContext.theme));

    useEffect(() => {
        setStroke(getChartFontColor(themeContext.theme))
    }, [themeContext.theme]);

    const width = window.innerWidth;
    const isMobileSize = width < 850;
    const vibe = payload.value;
    const emoji = getEmojiForVibe(vibe);


    const fontSize = isMobileSize ? 18 : 16;
    const dy = isMobileSize ? 16 : 6;
    const tspanDy = width < 800 ? 12 : 20;

    const xo = xOffset === undefined ? 0 : xOffset
    const yo = yOffset === undefined ? 0 : yOffset

    const {t} = useTranslation()

    return (
        <g transform={`translate(${x + xo},${y + yo})`}>
            <text
                x={0}
                y={15}
                dy={dy}
                textAnchor="middle"
                fill="#666"
                fontSize={isMobileSize ? 22 : 18}
                fontWeight={80}
                className="emoji"
                stroke={stroke}
            >
                {emoji}
                {!isMobileSize && (
                    <tspan x={0} dy={tspanDy} fontSize={fontSize - 2} style={{fontWeight: "200"}}>
                        {t("vibes." + vibe)}
                    </tspan>
                )}
            </text>
        </g>
    );
};