import React from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon
} from 'react-share';
import './ShareButtons.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt} from '@fortawesome/free-solid-svg-icons';


interface ShareButtonsProps {
    url: string;
    title: string;
    description: string
}

const ShareButtons: React.FC<ShareButtonsProps> = ({url, title, description}) => {

    const handleGenericShare = () => {
        if (navigator.share) {
            navigator.share({
                title: title,
                text: description,
                url: url,
            }).catch(error => console.error('Error sharing:', error));
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    const buttonColor = 'var(--app-background-color)';
    const iconColor = 'var(--text-secondary-color)';

    return (
        <div className="share-buttons">
            <FacebookShareButton url={url} hashtag="#VibeAnalysis">
                <FacebookIcon
                    size={32}
                    iconFillColor={iconColor}
                    bgStyle={{fill: buttonColor}}
                    lightingColor={iconColor}
                    round
                />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title} hashtags={["VibeAnalysis"]}>
                <XIcon
                    size={32}
                    iconFillColor={iconColor}
                    bgStyle={{fill: buttonColor}}
                    round
                />
            </TwitterShareButton>
            <LinkedinShareButton url={url} title={title}>
                <LinkedinIcon
                    size={32}
                    iconFillColor={iconColor}
                    bgStyle={{fill: buttonColor}}
                    round
                />
            </LinkedinShareButton>
            <div onClick={handleGenericShare} className="share-button"
                 style={{backgroundColor: buttonColor, color: iconColor}}>
                <FontAwesomeIcon icon={faShareAlt}/>
            </div>
        </div>
    );
};

export default ShareButtons;
