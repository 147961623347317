export const vibes: { [key: string]: string } = {
    anger: "😡",
    sadness: "😞",
    fear: "😱",
    surprise: "😲",
    neutral: "😐",
    disgust: "🤮",
    joy: "😄",
    negative: "☹️",
    positive: "😀",
    admiration: "👏",
    amusement: "😆",
    annoyance: "😒",
    approval: "👍",
    caring: "🤗",
    confusion: "😕",
    curiosity: "🤔",
    desire: "😍",
    disappointment: "😔",
    disapproval: "👎",
    embarrassment: "😳",
    excitement: "🤩",
    gratitude: "🙏",
    grief: "😭",
    love: "❤️",
    nervousness: "😬",
    optimism: "😊",
    pride: "😌",
    realization: "💡",
    relief: "😌",
    remorse: "😞"
};

export function getEmojiForVibe(vibe: string): string {
    return vibes[vibe] || '';
}

export const getStrongestVibeExcludeNeutral = (results: { [key: string]: number }): string => {
    const sorted = Object.entries(results)
        .filter(([vibe]) => vibe !== 'neutral') // Exclude neutral
        .sort(([, valueA], [, valueB]) => valueB - valueA); // Sort by values in descending order

    return sorted.length > 0 ? sorted[0][0] : 'neutral';
}

const transparency = 0.7;

export const colorMapping: { [key: string]: string } = {
    "anger": `rgba(255, 111, 97, ${transparency})`,       // Soft Red
    "sadness": `rgba(106, 90, 205, ${transparency})`,     // SlateBlue
    "fear": `rgba(178, 34, 34, ${transparency})`,         // FireBrick
    "surprise": `rgba(255, 215, 0, ${transparency})`,     // Gold
    "neutral": `rgba(192, 192, 192, ${transparency})`,    // Silver
    "disgust": `rgba(154, 205, 50, ${transparency})`,     // YellowGreen
    "joy": `rgba(255, 215, 0, ${transparency})`,          // Gold
    "negative": `rgba(169, 169, 169, ${transparency})`,   // DarkGray
    "positive": `rgba(152, 251, 152, ${transparency})`,   // PaleGreen
    "admiration": `rgba(255, 182, 193, ${transparency})`, // LightPink
    "amusement": `rgba(255, 160, 122, ${transparency})`,  // LightSalmon
    "annoyance": `rgba(244, 164, 96, ${transparency})`,   // SandyBrown
    "approval": `rgba(144, 238, 144, ${transparency})`,   // LightGreen
    "caring": `rgba(255, 182, 193, ${transparency})`,     // LightPink
    "confusion": `rgba(255, 140, 0, ${transparency})`,    // DarkOrange
    "curiosity": `rgba(32, 178, 170, ${transparency})`,   // LightSeaGreen
    "desire": `rgba(255, 105, 180, ${transparency})`,     // HotPink
    "disappointment": `rgba(205, 92, 92, ${transparency})`,// IndianRed
    "disapproval": `rgba(139, 69, 19, ${transparency})`,  // SaddleBrown
    "embarrassment": `rgba(222, 184, 135, ${transparency})`,// BurlyWood
    "excitement": `rgba(255, 69, 0, ${transparency})`,    // OrangeRed
    "gratitude": `rgba(152, 251, 152, ${transparency})`,  // PaleGreen
    "grief": `rgba(70, 130, 180, ${transparency})`,       // SteelBlue
    "love": `rgba(255, 20, 147, ${transparency})`,        // DeepPink
    "nervousness": `rgba(210, 180, 140, ${transparency})`,// Tan
    "optimism": `rgba(255, 255, 153, ${transparency})`,   // LightYellow
    "pride": `rgba(147, 112, 219, ${transparency})`,      // MediumPurple
    "realization": `rgba(176, 196, 222, ${transparency})`,// LightSteelBlue
    "relief": `rgba(216, 191, 216, ${transparency})`,     // Thistle
    "remorse": `rgba(165, 42, 42, ${transparency})`       // Brown
};