import React, {useState, useEffect} from 'react';
import './SearchComponent.css';
import {validateUrl} from '../../../utils/validation';

interface SearchComponentProps {
    onSearch: (data: any) => void;
    placeholders: string[];
    buttonText: string;
}

const SearchComponent: React.FC<SearchComponentProps> = ({onSearch, placeholders, buttonText}) => {
    const [queries, setQueries] = useState<string[]>(placeholders.map(() => ''));
    const [loading, setLoading] = useState(false);
    const [urlErrors, setUrlErrors] = useState<boolean[]>(placeholders.map(() => false));

    const isSingleSearch = placeholders.length === 1;
    const isMultiSearch = placeholders.length > 1;

    useEffect(() => {
        setUrlErrors(queries.map(query => !validateUrl(query)));
    }, [queries]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newQueries = [...queries];
        newQueries[index] = event.target.value;
        setQueries(newQueries);
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    const handleButtonClick = () => {
        setLoading(true);
        if (queries.length === 1) {
            onSearch({url: queries[0]});
        } else {
            onSearch({url_1: queries[0], url_2: queries[1]});
        }
    };

    return (
        <div className="search-container">
            <div className={isMultiSearch ? 'multi-input-container' : 'input-container'}>
                {queries.map((query, index) => (
                    <input
                        key={index}
                        type="text"
                        value={query}
                        onChange={(event) => handleInputChange(index, event)}
                        placeholder={placeholders[index]}
                        className={isMultiSearch ? 'multi-search-input' : 'search-input'}
                        onKeyPress={handleKeyPress}
                        disabled={loading}
                    />
                ))}
                <button
                    className={isMultiSearch ? 'multi-search-button' : 'search-button'}
                    onClick={handleButtonClick}
                    disabled={loading || urlErrors.some(error => error)}>
                    {loading ? 'Loading...' : buttonText}
                </button>


            </div>
            <div className="error-container">
                {queries.map((query, index) => query.length > 5 && urlErrors[index] && (
                    <p key={index} className="error-message">The article URL {index + 1} must be a valid URL!</p>
                ))}
            </div>
        </div>
    );
};

export default SearchComponent;
