import Theme from "./Theme";

export class Gradients {

    static gradientAlpha: number = 0.6;


    static hexToRgba(hex: string, alpha: number): string {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    static toRgba(color: string): string {
        return Gradients.hexToRgba(color, Gradients.gradientAlpha);
    }

    static getRandomGradient(theme: Theme): string {
        const lights = [
            ['#f6d365', '#fda085', '#fbc2eb'],
            ['#c184fa', '#8fd3f4', '#fccb90'],
            ['#fccb90', '#d57eeb', '#ff9a9e'],
            ['#ff9a9e', '#fecfef', '#a18cd1'],
            ['#a18cd1', '#fbc2eb', '#fad0c4'],
            ['#fad0c4', '#ffd1ff', '#ffecd2'],
            ['#ffecd2', '#fcb69f', '#fdd6d6'],
        ];

        const darks = [
            ['#232526', '#414345', '#6A6A6A'],
            ['#0f2027', '#203a43', '#2c5364'],
            ['#141E30', '#243B55', '#647D91'],
            ['#0F2027', '#203A43', '#2C5364'],
            ['#232526', '#414345', '#6A6A6A'],
            ['#2C3E50', '#4CA1AF', '#3A6073'],
            ['#1F1C2C', '#605c75', '#1a162c'],
            ['#141E30', '#243B55', '#3A6073'],
            ['#28313B', '#485461', '#28313B'],
            ['#232526', '#1E3C72', '#2A5298'],
        ];


        const gradients = theme.isLight() ? lights : darks;
        const randomIndex = Math.floor(Math.random() * gradients.length);
        const colors = gradients[randomIndex].map(color => Gradients.toRgba(color)).join(', ');

        return `linear-gradient(to right top, ${colors})`;
    }

    static getGradientForVibe(vibe: string, theme: Theme): string {
        const lightGradients: { [key: string]: string[] } = {
            positive: ['#a1c4fd', '#c2e9fb', '#d4fc79'],
            negative: ['#ff9a9e', '#fecfef', '#ff6f69'],
            neutral: ['#d4fc79', '#96e6a1', '#84fab0'],
            anger: ['#ff6f69', '#ff0000', '#8B0000'],
            disgust: ['#8fd3f4', '#4CAF50', '#2E8B57'],
            fear: ['#a18cd1', '#8A2BE2', '#4B0082'],
            joy: ['#f6d365', '#fda085', '#FFD700'],
            sadness: ['#a1c4fd', '#4682B4', '#0000FF'],
            surprise: ['#fccb90', '#d57eeb', '#9370DB'],
            admiration: ['#ffafbd', '#ffc3a0', '#ff9a9e'],
            amusement: ['#ff6f69', '#ff7eb3', '#ff758c'],
            annoyance: ['#ff0844', '#ffb199', '#ff0844'],
            approval: ['#2E8B57', '#3CB371', '#2F4F4F'],
            caring: ['#fbc2eb', '#a6c1ee', '#84fab0'],
            confusion: ['#fbc7d4', '#9796f0', '#fbc2eb'],
            curiosity: ['#f3e7e9', '#e3eeff', '#a7d7c5'],
            desire: ['#ffafbd', '#ffc3a0', '#ff9a9e'],
            disappointment: ['#d299c2', '#f6d365', '#e2b0ff'],
            disapproval: ['#ff0844', '#ffb199', '#ff0844'],
            embarrassment: ['#e1ccec', '#fbc2eb', '#e1ccec'],
            excitement: ['#FFD700', '#FFA500', '#FF8C00'],
            gratitude: ['#ffecd2', '#fcb69f', '#ffecd2'],
            grief: ['#b7c6e3', '#f3e7e9', '#b7c6e3'],
            love: ['#FF69B4', '#FF1493', '#C71585'],
            nervousness: ['#4B0082', '#483D8B', '#2F4F4F'],
            optimism: ['#d4fc79', '#96e6a1', '#84fab0'],
            pride: ['#FFD700', '#FFA500', '#FF8C00'],
            realization: ['#f3e7e9', '#e3eeff', '#a7d7c5'],
            relief: ['#d4fc79', '#96e6a1', '#84fab0'],
            remorse: ['#d299c2', '#f6d365', '#e2b0ff'],
        };

        const darkGradients: { [key: string]: string[] } = {
            positive: ['#2E8B57', '#3CB371', '#2F4F4F'],
            negative: ['#8B0000', '#B22222', '#2F4F4F'],
            neutral: ['#696969', '#808080', '#A9A9A9'],
            anger: ['#8B0000', '#B22222', '#DC143C'],
            disgust: ['#2E8B57', '#3CB371', '#556B2F'],
            fear: ['#4B0082', '#483D8B', '#2F4F4F'],
            joy: ['#FFD700', '#FFA500', '#FF8C00'],
            sadness: ['#00008B', '#0000CD', '#191970'],
            surprise: ['#9370DB', '#8A2BE2', '#4B0082'],
            admiration: ['#ff0844', '#ffb199', '#ff0844'],
            amusement: ['#ff6f69', '#ff7eb3', '#ff758c'],
            annoyance: ['#8B0000', '#B22222', '#DC143C'],
            approval: ['#2E8B57', '#3CB371', '#2F4F4F'],
            caring: ['#2E8B57', '#3CB371', '#556B2F'],
            confusion: ['#4B0082', '#483D8B', '#2F4F4F'],
            curiosity: ['#4B0082', '#483D8B', '#2F4F4F'],
            desire: ['#8B0000', '#B22222', '#DC143C'],
            disappointment: ['#8B0000', '#B22222', '#DC143C'],
            disapproval: ['#8B0000', '#B22222', '#DC143C'],
            embarrassment: ['#8B0000', '#B22222', '#DC143C'],
            excitement: ['#FFD700', '#FFA500', '#FF8C00'],
            gratitude: ['#2E8B57', '#3CB371', '#2F4F4F'],
            grief: ['#00008B', '#0000CD', '#191970'],
            love: ['#FF69B4', '#FF1493', '#C71585'],
            nervousness: ['#4B0082', '#483D8B', '#2F4F4F'],
            optimism: ['#d4fc79', '#96e6a1', '#84fab0'],
            pride: ['#FFD700', '#FFA500', '#FF8C00'],
            realization: ['#f3e7e9', '#e3eeff', '#a7d7c5'],
            relief: ['#d4fc79', '#96e6a1', '#84fab0'],
            remorse: ['#d299c2', '#f6d365', '#e2b0ff'],
        };

        const gradients = theme.isLight() ? lightGradients[vibe] : darkGradients[vibe];
        if (!gradients) return '';

        const colors = gradients.map(color => Gradients.toRgba(color)).join(', ');

        return `linear-gradient(to right top, ${colors})`;
    }

}
