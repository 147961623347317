import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


import en from './i18n/en.json';
import hu from './i18n/hu.json';
import fr from './i18n/fr.json';
import de from './i18n/de.json';
import es from './i18n/es.json';
import it from './i18n/it.json';
import pt from './i18n/pt.json';
import nl from './i18n/nl.json';
import ru from './i18n/ru.json';
import ja from './i18n/ja.json';
import ko from './i18n/ko.json';
import he from './i18n/he.json';
import pl from './i18n/pl.json';
import sv from './i18n/sv.json';
import da from './i18n/da.json';
import fi from './i18n/fi.json';
import no from './i18n/no.json';
import cs from './i18n/cs.json';
import el from './i18n/el.json';
import uk from './i18n/uk.json';
import sk from './i18n/sk.json';
import lt from './i18n/lt.json';
import lv from './i18n/lv.json';
import et from './i18n/et.json';

const resources = {
    en_us: {translation: en},
    en_gb: {translation: en},
    en_eu: {translation: en},
    hu: {translation: hu},
    fr: {translation: fr},
    de: {translation: de},
    es: {translation: es},
    it: {translation: it},
    pt: {translation: pt},
    nl: {translation: nl},
    ru: {translation: ru},
    ja: {translation: ja},
    ko: {translation: ko},
    he: {translation: he},
    pl: {translation: pl},
    sv: {translation: sv},
    da: {translation: da},
    fi: {translation: fi},
    no: {translation: no},
    cs: {translation: cs},
    el: {translation: el},
    uk: {translation: uk},
    sk: {translation: sk},
    lt: {translation: lt},
    lv: {translation: lv},
    et: {translation: et},
} as const;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en_eu',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage'],
        },
    });

export default i18n;
