import React from 'react';
import SearchComponent from './SearchComponent';
import {ArticleComparisonRequest} from '../../../vibelens/VibeLensServer';
import {useTranslation} from "react-i18next";

interface ComparisonSearchProps {
    onSearch: (data: ArticleComparisonRequest) => void;
}

const ComparisonSearch: React.FC<ComparisonSearchProps> = ({onSearch}) => {
    const {t} = useTranslation()
    return (
        <SearchComponent
            onSearch={onSearch}
            placeholders={[t('comparisons.search.placeholder1'), t('comparisons.search.placeholder2')]}
            buttonText={t('comparisons.search.button')}
        />
    );
};

export default ComparisonSearch;
