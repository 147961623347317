import React, {useEffect} from 'react';
import {Dropdown, Nav, Navbar, Offcanvas} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import {useTheme} from "../theme/ThemeContext";
import {useTranslation} from 'react-i18next';


type LanguageOption = {
    display: string;
    code: string;
    flag: string
};

const languageOptions: LanguageOption[] = [
    {"display": "Magyar", "code": "hu", "flag": "🇭🇺"},
    {"display": "English (Europe)", "code": "en_eu", "flag": "🇪🇺"},
    {"display": "English (UK)", "code": "en_gb", "flag": "🇬🇧"},
    {"display": "English (US)", "code": "en_us", "flag": "🇺🇸"},
    {"display": "Français", "code": "fr", "flag": "🇫🇷"},
    {"display": "Deutsch", "code": "de", "flag": "🇩🇪"},
    {"display": "Español", "code": "es", "flag": "🇪🇸"},
    {"display": "Italiano", "code": "it", "flag": "🇮🇹"},
    {"display": "Português", "code": "pt", "flag": "🇵🇹"},
    {"display": "Nederlands", "code": "nl", "flag": "🇳🇱"},
    {"display": "Русский", "code": "ru", "flag": "🇷🇺"},
    {"display": "日本語", "code": "ja", "flag": "🇯🇵"},
    {"display": "한국어", "code": "ko", "flag": "🇰🇷"},
    {"display": "עברית", "code": "he", "flag": "🇮🇱"},
    {"display": "Polski", "code": "pl", "flag": "🇵🇱"},
    {"display": "Svenska", "code": "sv", "flag": "🇸🇪"},
    {"display": "Dansk", "code": "da", "flag": "🇩🇰"},
    {"display": "Suomi", "code": "fi", "flag": "🇫🇮"},
    {"display": "Norsk", "code": "no", "flag": "🇳🇴"},
    {"display": "Čeština", "code": "cs", "flag": "🇨🇿"},
    {"display": "Ελληνικά", "code": "el", "flag": "🇬🇷"},
    {"display": "Українська", "code": "uk", "flag": "🇺🇦"},
    {"display": "Slovenčina", "code": "sk", "flag": "🇸🇰"},
    {"display": "Lietuvių", "code": "lt", "flag": "🇱🇹"},
    {"display": "Latviešu", "code": "lv", "flag": "🇱🇻"},
    {"display": "Eesti", "code": "et", "flag": "🇪🇪"}
];


const Header: React.FC = () => {
    const [showOffcanvas, setShowOffcanvas] = React.useState(false);

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);

    const themeContext = useTheme()
    const {t, i18n: {language, changeLanguage}} = useTranslation();


    useEffect(() => {
        if (!language) {
            handleChangeLanguage('en');
        } else {
            handleChangeLanguage(language);
        }
        console.log(t('header.comparisons'))
    }, [language]);


    const findCurrentLanguage = () => {
        if (!language) {
            return languageOptions.find(lang => lang.code === "en")?.display;
        } else {
            return languageOptions.find(lang => lang.code === language)?.display;
        }
    }


    const sortedLanguages = languageOptions.sort((a, b) => a.display.localeCompare(b.display));

    const [currentLanguage, setCurrentLanguage] = React.useState(findCurrentLanguage());


    const handleChangeLanguage = (lng: string) => {
        console.log('Changing language to:', lng)
        changeLanguage(lng);
        setCurrentLanguage(languageOptions.find(lang => lang.code === lng)?.display)
    };

    const offcanvasStyle = {
        backgroundColor: themeContext.theme.isDark() ? 'rgba(20, 20, 20, 0.7)' : 'rgba(255, 255, 255, 0.2)',
        color: themeContext.theme.isDark() ? 'white' : 'black'
    };


    return (
        <>

            <Navbar expand="lg" className="header">
                <Navbar.Brand href="/" className="site-name">vibelens</Navbar.Brand>
                <div
                    style={{
                        fontFamily: 'NotoColorFlag, sans-serif',
                        position: 'absolute',
                        width: '0',
                        height: '0',
                        overflow: 'hidden',
                        opacity: 0,
                    }}
                >
                    .
                </div>
                <Nav className="navbar-menu">
                    <Nav.Link href="/articles/analysis" className='navbar-element'>{t('header.analysis')}</Nav.Link>
                    <Nav.Link href="/articles/comparisons"
                              className='navbar-element'>{t('header.comparisons')}</Nav.Link>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}
                                   className="nav-toggle navbar-element"/>
                </Nav>

            </Navbar>

            <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton style={offcanvasStyle}>
                    <Offcanvas.Title>{t('menu.settings')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={offcanvasStyle}>
                    <div className="offcanvas-section">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="theme-dropdown" className="offcanvas-dropdown">
                                {t('menu.theme.select')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.ItemText className={'dropdown-header-vl'}>{t('menu.theme.current') + ": " + t("menu.theme." + themeContext.themeName)}</Dropdown.ItemText>
                                <Dropdown.Item
                                    onClick={() => themeContext.changeTheme('device')}>{t('menu.theme.device')}</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => themeContext.changeTheme('dark')}>{t('menu.theme.dark')}</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => themeContext.changeTheme('light')}>{t('menu.theme.light')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="offcanvas-section">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="language-dropdown" className="offcanvas-dropdown">
                                {t('menu.language.select')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.ItemText className={'dropdown-header-vl'}>{t('menu.language.current') + ": " + currentLanguage}</Dropdown.ItemText>
                                <div className="two-column-menu">
                                    {sortedLanguages.map((lang) => (
                                        <Dropdown.Item key={lang.display}
                                                       onClick={() => handleChangeLanguage(lang.code)}>
                                            <text className="flag">{lang.flag + " "}</text>
                                            {lang.display}</Dropdown.Item>
                                    ))}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;
