import Theme from "../theme/Theme";

const lightAxisColor = "#292929"
const lightFontColor = "#292929"

const darkAxisColor = "#c5c5c5"
const darkFontColor = "#c5c5c5"


export function getChartAxisColor(theme: Theme) {
    return theme.isLight() ? lightAxisColor : darkAxisColor;
}

export function getChartFontColor(theme: Theme): string {
    return theme.isLight() ? lightFontColor : darkFontColor;
}