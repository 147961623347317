import {ArticleComparisonResponse} from "../../../vibelens/VibeLensServer";
import {colorMapping, getEmojiForVibe} from "../../../utils/vibes";

export const chartDataColor1 = "#8884d8"
export const chartDataColor2 = "#82ca9d"

export const percentageFormatter = (value: number) => `${(value * 100).toFixed(2)}%`;
export const percentageFormatterNoFractions = (value: number) => `${(value * 100).toFixed(0)}%`;

export function toComparableChartItem(result: ArticleComparisonResponse) {
    return ([vibe, value]: [string, number]) => {
        const value2: number = (result.article_2.content_analysis.results as { [key: string]: number })[vibe];

        return {
            vibe: vibe,
            value1: value,
            value2: value2,
            emoji: getEmojiForVibe(vibe)
        };
    };
}

export function toChartItem([vibe, value]: [string, number]) {
    return {
        vibe: vibe,
        value: value,
        emoji: getEmojiForVibe(vibe),
        color: colorMapping[vibe]
    }
}
