import React, {useEffect, useState} from 'react';
import {TooltipProps} from 'recharts';
import {chartDataColor1, chartDataColor2, percentageFormatter} from './ChartFunctions';
import Theme from "../../../theme/Theme";
import {getChartFontColor} from "../../../utils/ChartFormat";
import {getEmojiForVibe} from "../../../utils/vibes";
import {useTheme} from "../../../theme/ThemeContext";
import {useTranslation} from "react-i18next";


interface ThemedTooltipProps extends TooltipProps<any, any> {
}


const CustomTooltip: React.FC<ThemedTooltipProps> = ({payload, label}) => {

    const themeContext = useTheme()
    const [backgroundColor, setBackgroundColor] = useState(getChartFontColor(themeContext.theme));
    const [opacity, setOpacity] = useState(getOpacity(themeContext.theme))
    const {t} = useTranslation()

    useEffect(() => {
        setBackgroundColor(getTooltipBackgroundColor(themeContext.theme))
        setOpacity(getOpacity(themeContext.theme))
    }, [themeContext.theme]);

    if (!payload || payload.length === 0) return null;

    return (
        <div style={{
            backgroundColor: backgroundColor,
            borderRadius: '8px',
            padding: '8px',
            color: 'var(--text-secondary-color)',
            fontSize: '14px',
            opacity: opacity
        }}>
            <div>
                <text className="emoji">{getEmojiForVibe(label)}</text>
                <strong> {t("vibes." + label)}</strong></div>
            {payload.map((entry, index) => (
                <div key={index} style={{marginTop: '8px', color: getColor(index)}}>
                    {entry.name}: {percentageFormatter(entry.value)}
                </div>
            ))}
        </div>
    );
};

const getTooltipBackgroundColor = (theme: Theme): string => {
    return theme.isDark() ? 'black' : 'white';
};

const getOpacity = (theme: Theme): number => {
    return theme.isDark() ? 0.9 : 0.9
}

function getColor(index: number) {
    return index % 2 === 0 ? chartDataColor1 : chartDataColor2;
}


export default CustomTooltip;
