import React from 'react';
import {OverlayTrigger, ProgressBar, Tooltip} from 'react-bootstrap';
import './ProgressBarChart.css';

interface ProgressBarWithTooltipProps {
    percentage: number;
    label: string;
    tooltip: string
}

const ProgressBarChart: React.FC<ProgressBarWithTooltipProps> = ({percentage, label, tooltip}) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="progress-tooltip" className="custom-tooltip">
                    {tooltip}
                </Tooltip>
            }
        >
            <div className="progress-bar-container">
                <ProgressBar className='progress-bar' now={percentage}/>
                <div className="progress-bar-label-div">
                    <span className='progress-bar-label'>{label}</span>
                </div>
            </div>
        </OverlayTrigger>
    );
};

export default ProgressBarChart;